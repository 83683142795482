section.converter-prorest-hevc {
    max-width: 770px;

    @media (min-width: 576px) {
        &.container {
            max-width: 540px;
        }
    }
    @media (min-width: 768px) {
        &.container {
            max-width: 720px;
        }
    }

    .container-loader {
        margin-top: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .container-form {
        p {
            margin-bottom: 1rem;
        }
        .container-bottom {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            margin-top: 10px;
        }
    }
}
