@import '/src/styles/colors';

.input-field {
    position: relative;

    .label {
        display: block;
        margin-bottom: 5px;
    }

    input {
        font-size: 1.2rem;
        padding: 3px 10px;
        border-radius: 5px;
        border: 2px solid $blue-light;

        &:focus {
            outline: none;
            border: 2px solid $blue;
        }

        &.error {
            border: 2px solid $red;
        }
    }

    .error-text {
        position: absolute;
        bottom: -20px;
        display: block;
        margin-top: 5px;
        color: $red;
        font-size: 0.9rem;
    }
}
