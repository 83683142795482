$white: #ffffff;
$blue: #4eacbf;
$red: #d84c51;
$yellow: #e9ad15;
$pink: #c65dc0;
$grey: #d0cfcf;

$blue-dark: #3e8a99;
$red-dark: #c24347;
$yellow-dark: #d9a113;
$grey-dark: #c5c5c5;

$blue-light: #dbeef2;

$grey1: #efefef;
$grey2: #cccccc;
$grey3: #303030;
