@import '/src/styles/colors';

.login-page {
    min-height: 100vh;
    background-color: $grey1;
    display: flex;
    justify-content: center;
    align-items: center;

    .card-login {
        background-color: $white;
        max-width: 300px;
        padding: 30px;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        -webkit-box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.05);
        -moz-box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.05);
        box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.05);

        img {
            max-width: 200px;
        }

        p {
            color: $grey3;
            text-align: start;
            margin-bottom: 30px;
        }

        .custom-button {
            width: 100%;
            padding: 10px;
            font-size: 18px;
            font-weight: 500;
            letter-spacing: 0.1em;
        }
    }
}
