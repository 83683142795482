@import '/src/styles/colors';
@import '/src/styles/mixins';

.header {
    background-color: $grey1;
    padding: 5px 0px;
    margin-bottom: 30px;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);

    .inner-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
    }

    .container-logo img {
        height: 40px;
    }
}
