.loader-converter {
    position: absolute;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(155, 155, 155, 0.4);
    z-index: 999;
}
