@import '/src/styles/colors';

.custom-button {
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;

    text-transform: uppercase;
    font-size: 0.8rem;
    font-weight: 600;
    color: $white;
    background: $blue;
    border: 1.5px solid $blue;
    border-radius: 4px;
    padding: 5px 10px;
    transition: 0.2s;
    min-height: 33.5px;

    &:hover {
        background: $blue-dark;
        border: 1.5px solid $blue-dark;
        transition: 0.2s;
    }

    &.grey {
        color: $grey3;
        background: $grey;
        border: 1.5px solid $grey;

        &:hover {
            background: $grey-dark;
            border: 1.5px solid $grey-dark;
            transition: 0.2s;
        }
    }

    &.yellow {
        color: $white;
        background: $yellow;
        border: 1.5px solid $yellow;

        &:hover {
            background: $yellow-dark;
            border: 1.5px solid $yellow-dark;
            transition: 0.2s;
        }
    }

    &.red {
        color: $white;
        background: $red;
        border: 1.5px solid $red;

        &:hover {
            background: $red-dark;
            border: 1.5px solid $red-dark;
            transition: 0.2s;
        }
    }

    &.disabled {
        color: $white;
        background: $grey1;
        border: 1.5px solid $grey1;

        &:hover {
            cursor: inherit;
        }
    }
}
