@import '/src/styles/colors';
@import '/src/styles/mixins';

.ReactModal__Overlay {
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ReactModal__Overlay .ReactModal__Content {
    inset: 0 !important;
    position: relative !important;
    max-width: 80%;
    max-height: 80vh;

    .header-modal {
        min-width: 0px;
        min-height: 32px;
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;

        @include md {
            min-width: 400px;
        }

        h3 {
            margin: 0;
            margin-right: 55px;
        }

        button {
            outline: inherit;
            padding: 0;
            border: none;
        }

        .close {
            position: absolute;
            right: 18px;
            top: 18px;
            width: 32px;
            height: 32px;
            opacity: 0.3;

            &:hover {
                opacity: 1;
                cursor: pointer;
            }
            &:before,
            &:after {
                position: absolute;
                left: 15px;
                content: ' ';
                height: 33px;
                width: 2px;
                background-color: $grey3;
            }
            &:before {
                transform: rotate(45deg);
            }
            &:after {
                transform: rotate(-45deg);
            }
        }
    }
}
