$sm: '(min-width: 576px)';
$md: '(min-width: 900px)';
$lg: '(min-width: 992px)';
$xl: '(min-width: 1200px)';
$xxl: '(min-width: 1400px)';

@mixin sm {
    @media #{$sm} {
        @content;
    }
}

@mixin md {
    @media #{$md} {
        @content;
    }
}

@mixin lg {
    @media #{$lg} {
        @content;
    }
}

@mixin xl {
    @media #{$xl} {
        @content;
    }
}

@mixin xxl {
    @media #{$xxl} {
        @content;
    }
}
